import React, {useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {Box, Card, Cell, Layout, Page, WixDesignSystemProvider} from "@wix/design-system";

import AnalyticsHeader from "../components/analytics/AnalyticsHeader";
import BarChart from "../components/analytics/BarChart";
import DoughnutChart from "../components/analytics/DoughnutChart";
import TableAnalytics from "../components/analytics/TableAnalytics";
import {getViewAnalytics} from "../api/analyticsClient";
import moment from "moment/moment";
import {AggregationPeriod, VendorProductId, ViewAnalyticsResponse, ViewSource,} from "../models/models";
import {useInstance} from "../store/instanceStore";
import {useShortLinkById} from "../store/shortLinkStore";
import {getStartDate} from "../helpers/dateRangeUtil";
import ViewModeSegmentedToggle from "../components/analytics/ViewModeSegmentedToggle";
import LinkSubtitle from "../components/common/LinkSubtitle";
import Blur from "../components/common/Blur";
import {ToastContainer} from "react-toastify";
import {showNotification} from "../helpers/notificationHelper";

const Analytics: React.FC = () => {

  const ADVANCED_ANALYTICS_MIN_DATE = new Date("2025-02-26");

  const { id: shortLinkId } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const instance = useInstance(searchParams);
  const shortLink = useShortLinkById(Number(shortLinkId), searchParams);
  const isAdvancedAnalyticsDisabled = instance.vendorProductId !== VendorProductId.business && instance.vendorProductId !== VendorProductId.ultimate;

  const [aggregationPeriod, setAggregationPeriod] = useState<AggregationPeriod>(AggregationPeriod.Daily);
  const [startDate, setStartDate] = useState<Date>(getStartDate(shortLink.createdDate, aggregationPeriod));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [analyticTypeBySource, setAnalyticTypeBySource] = useState<string>(Boolean(shortLink.qrCodeId) ? "Clicks & Scans" : "Clicks");
  const [selectedDeviceViewMode, setSelectedDeviceViewMode] = useState<"count" | "percentage">("count");
  const [selectedBrowserViewMode, setSelectedBrowserViewMode] = useState<"count" | "percentage">("count");

  const [datasetVisibility, setDatasetVisibility] = useState<Map<ViewSource, boolean>>(
      new Map([
          [ ViewSource.Browser, true ],
          [ ViewSource.Qr, Boolean(shortLink.qrCodeId) ]
      ])
  );

  const [viewAnalytics, setViewAnalytics] = useState<ViewAnalyticsResponse>({
      linkId: 0,
      views: new Map(),
      qrViews: new Map(),
      countryViews: new Map(),
      cityViews: new Map(),
      deviceTypes: new Map(),
      operatingSystems: new Map(),
      browsers: new Map()
  });

  const navigate = useNavigate();

  useEffect(() => {
      const fetchAnalytics = async () => {
          try {
              const response = await getViewAnalytics({
                  linkId: shortLink.id,
                  startDate: moment(startDate).format('YYYY-MM-DD'),
                  endDate: moment(endDate).format('YYYY-MM-DD'),
                  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  aggregationPeriod,
                  viewSources: Array.from(datasetVisibility.entries())
                      .filter(([_, value]) => value)
                      .map(([key]) => key)
              }, searchParams);
              setViewAnalytics(response.data);
          } catch (error) {
              console.error("Error fetching analytics:", error);
          }
      };
      fetchAnalytics();
    }, [shortLink, startDate, endDate, aggregationPeriod, datasetVisibility, searchParams]);

  const handleAggregationPeriodChange = (newPeriod: AggregationPeriod, start: Date, end: Date) => {
      setAggregationPeriod(newPeriod);
      setStartDate(start);
      setEndDate(end);
  };

  const handleDateRangeChange = (start: Date, end: Date) => {
      if (start.getUTCDate() <= ADVANCED_ANALYTICS_MIN_DATE.getUTCDate()) {
          const formattedDate = ADVANCED_ANALYTICS_MIN_DATE.toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
          });
          showNotification(`Device, browser, OS and location-related analytics is accessible starting from ${formattedDate}.`)
      }

      setStartDate(start);
      setEndDate(end);
  };

  const handleLegendClick = (visibility: Map<ViewSource, boolean>) => {
      setDatasetVisibility(visibility);
      let title = "";
      if (visibility.get(ViewSource.Browser) && visibility.get(ViewSource.Qr)) {
          title = "Clicks & Scans";
      } else if (visibility.get(ViewSource.Browser)) {
          title = "Clicks";
      } else if (visibility.get(ViewSource.Qr)) {
          title = "Scans";
      }
      setAnalyticTypeBySource(title);
  };

  return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
          <Page height="100vh">
              <Page.Header title="TinyLinks Analytics"
                           subtitle={<LinkSubtitle subtitle={shortLink.title}/>}
                           minimized={true}
                           showBackButton
                           onBackClicked={() => {navigate(`/?instance=${searchParams.get("instance")}`)}}
                           actionsBar={
                             <AnalyticsHeader isQrCodeGenerated={Boolean(shortLink.qrCodeId)}
                                              aggregationPeriod={aggregationPeriod}
                                              createdDate={shortLink.createdDate}
                                              vendorProductId={instance.vendorProductId}
                                              onLegendClick={handleLegendClick}
                                              onAggregationPeriodChange={handleAggregationPeriodChange}
                                              onDateRangeChange={handleDateRangeChange}
                             />
                           }
              />
              <Page.Content>
                  <Layout cols={2}>
                      <Cell span={2}>
                          <Box height="40vh">
                              <Card showShadow stretchVertically>
                                  <Card.Header title={`${analyticTypeBySource} over time`} />
                                  <Card.Divider />
                                  <Card.Content>
                                      <BarChart views={viewAnalytics.views}
                                                qrViews={viewAnalytics.qrViews}
                                                aggregationPeriod={aggregationPeriod}
                                                datasetVisibility={datasetVisibility} />
                                  </Card.Content>
                              </Card>
                          </Box>
                      </Cell>
                      <Cell span={1}>
                          <Box height="40vh">
                              <Card showShadow stretchVertically>
                                  <Card.Header title={`${analyticTypeBySource} by browser`}
                                               suffix={ <ViewModeSegmentedToggle onChange={setSelectedBrowserViewMode} /> }
                                  />
                                  <Card.Divider />
                                  <Card.Content>
                                      <DoughnutChart title={analyticTypeBySource} dataset={viewAnalytics.browsers} viewMode={selectedBrowserViewMode} />
                                  </Card.Content>
                              </Card>
                          </Box>
                      </Cell>
                      <Cell span={1}>
                          <Box height="40vh" minHeight="400px">
                              <Card showShadow stretchVertically>
                                  <TableAnalytics title={analyticTypeBySource}
                                                  isAdvancedAnalyticsDisabled={isAdvancedAnalyticsDisabled}
                                                  countryData={viewAnalytics.countryViews}
                                                  cityData={viewAnalytics.cityViews} />
                              </Card>
                          </Box>
                      </Cell>
                      <Cell span={1}>
                          <Box height="40vh">
                              <Card showShadow stretchVertically>
                                  <Card.Header title={`${analyticTypeBySource} by operating system`}
                                               suffix={ <ViewModeSegmentedToggle disabled={isAdvancedAnalyticsDisabled} onChange={setSelectedDeviceViewMode} />}
                                  />
                                  <Card.Divider />
                                  <Card.Content>
                                      <Blur disabled={!isAdvancedAnalyticsDisabled}>
                                              <DoughnutChart title={analyticTypeBySource}
                                                             dataset={viewAnalytics.operatingSystems}
                                                             viewMode={selectedDeviceViewMode}/>
                                      </Blur>
                                  </Card.Content>
                              </Card>
                          </Box>
                      </Cell>
                  </Layout>
              </Page.Content>
          </Page>
          <ToastContainer toastStyle={{ width: "250px", height: "75px" }}/>
    </WixDesignSystemProvider>
  );
};

export default Analytics;
